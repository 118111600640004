import { Box, Skeleton } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryKey } from '../../../framework/constants/QueryKey';
import { useConfirmationDialog } from '../../../framework/hooks/useConfirmationDialog';
import { CategoriesService } from '../../../framework/services/api/CategoriesService';
import { CategoryForm, TCategoryForm } from '../../organisms/CategoryForm';


export const EditCategoryPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {data, isError, isLoading, isRefetching} = useQuery([QueryKey.CATEGORY], () => CategoriesService.GetOne(id!))
  const mutationUpdate = useMutation(
    (formData: TCategoryForm) => CategoriesService.Update(id!, formData),
    {onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CATEGORY])
      queryClient.invalidateQueries([QueryKey.CATEGORIES])
    }}
  );
  const mutationDelete = useMutation(
    (id: string) => CategoriesService.Delete(id),
    {onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.CATEGORY])
      queryClient.invalidateQueries([QueryKey.CATEGORIES])
      navigate({pathname: '/admin/categories'});
    }}
  );
  const {open, DialogElement} = useConfirmationDialog(() => mutationDelete.mutate(id!))

  if (isLoading || isRefetching) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  if (isError) return (
    <Box>
      Error!
    </Box>
  );

  return (
    <>
      {DialogElement}
      <CategoryForm onSubmit={(formData) => mutationUpdate.mutate(formData)} onDelete={open} category={{...data}} title="Edit category"/>
    </>
  );
};
