import { createHashRouter } from "react-router-dom";
import { BaseLayout } from "../../components/layouts/BaseLayout";
import { NavLayout } from "../../components/layouts/NavLayout";
import { CategoriesPage } from "../../components/pages/categories/CategoriesPage";
import { CreateCategoryPage } from "../../components/pages/categories/CreateCategoryPage";
import { EditCategoryPage } from "../../components/pages/categories/EditCategoryPage";
import { LoginPage } from "../../components/pages/auth/LoginPage";
import { SurveysPage } from "../../components/pages/surveys/SurveysPage";
import { ViewSurveyPage } from "../../components/pages/surveys/ViewSurveyPage";
import { CreateTemplatePage } from "../../components/pages/templates/CreateTemplatePage";
import { EditTemplatePage } from "../../components/pages/templates/EditTemplatePage";
import { TemplatesPage } from "../../components/pages/templates/TemplatesPage";

const router = createHashRouter([
  {
    path: '/',
    element: <BaseLayout/>,
    children: [
      {
        path: 'admin',
        element: <NavLayout/>,
        children: [
          {
            path: 'categories',
            element: <CategoriesPage/>
          },
          {
            path: 'categories/new',
            element: <CreateCategoryPage/>
          },
          {
            path: 'categories/:id',
            element: <EditCategoryPage/>
          },
          {
            path: 'templates',
            element: <TemplatesPage/>
          },
          {
            path: 'templates/new',
            element: <CreateTemplatePage/>
          },
          {
            path: 'templates/:id',
            element: <EditTemplatePage/>
          },
          {
            path: 'surveys',
            element: <SurveysPage/>
          },
          {
            path: 'surveys/:id',
            element: <ViewSurveyPage/>
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    element: <LoginPage/>
  }
]);

export default router;