import Button, {ButtonProps} from '@mui/material/Button';
import {Link} from 'react-router-dom';

import React, { PropsWithChildren } from 'react';

interface Props extends ButtonProps {
  to: string;
}

export const LinkButton = ({to, children, color}: PropsWithChildren<Props>) => {
  return (
    <Button to={to} component={Link} color={color}>
      {children}
    </Button>
  );
};
